import React from "react";
import "./style.scss";

const HeroSection = ({ title, subtitle, content, button }) => {
  return (
    <div className="hero__container">
      <div className="hero__width-control">
        <div className="hero__content">
          <h1 className="hero__title">{title}</h1>
          <h2 className="hero__subtitle">{subtitle}</h2>
          {content.map((description, index) => (
            <p key={index} className="hero__description">
              {description}
            </p>
          ))}
          <div className="hero__button">{button}</div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
