import React from "react";
import Layout from "../components/layout";
import HeroSection from "../components/HeroSection";
import { config } from "@fortawesome/fontawesome-svg-core";

import PrimaryButton from "../components/PrimaryButton";

 import NobsLogo from "../images/nobslogo.jpg";

config.autoAddCss = false;

const IndexPage = () => (
  <Layout navbarSettings={{ showMenu: true }}>
    <div className="top">
      <img alt ="" src={NobsLogo} />
    </div>

    <div className="support">
      <h2>if you have a guide</h2>
      <h4>and need help with an app-related issue</h4>

      <a href="https://support.aflete.com/hc/en-us/requests/new">
        <div className="button-get-support">get support</div>
      </a>
    </div>

    <HeroSection
      title="Meet your fave new fitness app"
      content={[
        "Bored of diet culture? Us too. Meet nobs, the no bs approach to fitness. With zero burpees",
      ]}
      button={
        <div className="join-today__container">
          <PrimaryButton
            label="Ok, tell me more"
            path="https://nobsapp.co.uk/"
          />
        </div>
      }
    />
  </Layout>
);

export default IndexPage;
